$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.counseling_sortButtonDiv {
  margin-top: 2rem;
}

.statisticsManagementDay2Day {
  .money-inputs {
    display: flex;
    align-items: center;

    p {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    input {
      height: auto !important;
      padding: 1rem;
      font-size: 1rem;
    }
  }

  .MuiInputBase-root {
    color: white;
  }
  .MuiInput-underline {
    border-bottom: 1px solid white;

    &:before,
    &:after,
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid white;
    }
  }

  .UpSearchLayer {
    width: 100%;

    .TotalTamplete {
      // width: 50%;
      height: 10rem;
      display: inline-flex;
      // justify-content: space-between;
      align-items: center;
      padding: 0 5rem;
      color: white;
      background-color: #96b0e4;
      border-radius: 0.85rem;
      // box-shadow: 0.6rem 0.6rem 1rem #0000001f;
      margin-bottom: 0.5rem;

      & > div {
        position: relative;

        &:not(:last-child):after {
          position: absolute;
          content: "";
          height: 7rem;
          width: 1px;
          background-color: white;
          right: -5rem;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child {
          margin-left: 10rem;
        }
      }

      & p {
        font-size: 1.2rem;
        display: flex;
        align-items: center;

        & span {
          margin-left: 0.5rem;
          font-size: 1.5rem;
          margin-top: -5px;

          & b {
            font-size: 2.5rem;
          }
        }
      }
    }

    .GlobalsearchBar {
      height: 5rem;
      padding-left: calc(5rem - 1.8rem);

      .searchButton {
        margin-left: 3rem;
      }

      & input {
        height: 2rem;
      }
    }
  }

  .GlobalTable {
    .GlobalTableHeader span {
      justify-content: flex-start !important;
    }
    & > div {
      grid-template-columns: repeat(2, 1fr) repeat(13, 2fr);
    }
  }

  .Controls {
    display: flex;
    justify-content: space-between;

    & > button {
      margin-top: 3.2rem;
    }
  }
}
