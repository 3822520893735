$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.UI-button {
  margin: $ui_button_margin;
  padding: $ui_button_padding;
  position: relative;
  border-radius: $ui_button_border_radius;
  color: $black;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  line-height: 1.5;
  z-index: 1;
  font-size: 1rem;
  -webkit-user-select: none;
  pointer-events: stroke;

  &.UI-button_primary {
    color: $primary;
  }

  // transparent style
  &.UI-button_transparent {
    &:before {
      content: "";
      background: rgba($black, 0.1);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transition: 0.25s;
      opacity: 0;
      z-index: -1;
      transform: scale(0.5);
      box-sizing: border-box;
    }

    &.UI-button_primary {
      &:before {
        background: rgba($primary, 0.1);
      }
    }

    &.UI-button_secondary {
      &:before {
        background: rgba($secondary, 0.1);
      }
    }

    &:hover {
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }

    &:active {
      &:before {
        transform: scale(0.9);
      }
    }

    &.active {
      &:before {
        transform: scale(1);
        opacity: 1;
        background: rgba($black, 0.2);
      }
    }

    &.UI-button_primary {
      &.active {
        &:before {
          background: rgba($primary, 0.2);
        }
      }
    }

    &.UI-button_secondary {
      &.active {
        &:before {
          background: rgba($secondary, 0.2);
        }
      }
    }
  }
}
