$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.totalManagementDetail {
  .recallRequestDateBar {
    display: flex;
    background-color: $primary;
    border-radius: 0.85rem;
    box-shadow: 0.6rem 0.6rem 1rem rgba(0, 0, 0, 0.12);
    color: white;
    padding: 1.3rem 2.6rem;
    width: 100%;
    height: 6.25rem;
    margin-top: 1.5rem;
    justify-content: center;

    & > div {
      align-self: center;
      display: flex;
      height: 100%;

      &:first-child {
        font-size: 1.5rem;
      }

      &:nth-child(n + 2) {
        margin-left: 1.8rem;
        padding-left: 0rem;
        font-weight: bold;
      }

      & > span {
        align-self: center;
        margin-right: 1rem;
      }

      .reservationDateInput {
        input {
          color: black;
          text-align: left;
          letter-spacing: 0.06rem;
        }
      }
    }
    .applyButton {
      height: 2.95rem;
      align-self: center;
      padding-left: 1.6rem;
      padding-right: 2.3rem;
      margin-left: 0.5rem;
      

      .MuiButton-label {
        span {
          font-size: 1.2rem;
          font-weight: bold;
          padding-left: 0.5rem;
          padding-top: 0.1rem;
        }
      }
    }
  }
}
