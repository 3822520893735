$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.counselingInfo {
  display: grid;
  font-size: 0.75rem;
  align-items: center;
  text-align: center;
  grid-template-columns: fr(1) fr(3.3) fr(3.3) fr(1.6) fr(1.6);
  grid-template-rows: repeat(4, fr(1));
  padding-top: 0.2rem;
  padding-bottom: 3rem;

  & > * {
    margin: 0 0.2rem;
  }

  .progressSelectInput {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .simpleSelectInput,
  .textInput {
    height: 100%;
    border-radius: 0.3rem;
    border: 1px solid rgba(0, 0, 0, 0.5);

    input {
      text-align: center;
      color: black;
    }

    .MuiSelect-root {
      justify-content: center;
    }
  }

  .ccTextArea,
  .cententTextArea {
    border-radius: 0.3rem;
    width: auto;
    height: 100%;
    resize: none;
    padding: 1rem;

    &:active,
    &:focus {
      outline: none;
    }
  }

  .ccTextArea {
    grid-column: 2/3;
    grid-row: 2/5;
  }

  .cententTextArea {
    grid-column: 3/4;
    grid-row: 2/5;
  }
}
