$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.patientInfo {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  align-items: center;
  text-align: center;

  & > div {
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: fr(1.5) // No
      fr(3.5) // 차트번호
      fr(1.5) // 구환
      fr(2.5) // 이름
      fr(3.5) // 전화번호
      fr(2) // 나이
      repeat(3, fr(3)) // 거주지역 & 경로
      fr(3) // 업체
      fr(4) // 세부경로
      fr(1.8) // 이번트 내원
      fr(6.8) // 소개환자 성함
      fr(5); // 소개환자 내원경로
  }

  .patientInfoHeader {
    height: 2.5rem;

    & > div:nth-child(7) {
      grid-column: auto;
    }
  }

  .patientInfoItem {
    align-items: center;
    margin-top: 0.5rem;

    & > div:nth-child(n + 2) {
      margin: 0 0.2rem;
    }

    .MuiCheckbox-root {
      border-radius: 0.3rem;
      border: 1px solid rgba(0, 0, 0, 0.5);
      height: 2rem;
      background-color: white;
    }

    .textInput,
    .simpleSelectInput {
      height: 2rem;

      input {
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: black;
      }

      .MuiSelect-root {
        justify-content: center;
      }
    }

    .simpleSelectInput {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }

  .referralPatientVisitRouteDiv,
  .phoneInputDiv,
  .referralPatientIndexDiv {
    position: relative;

    .previewInputsSelect {
      position: absolute;
      bottom: -47px;
      width: 100%;
    }

    button {
      background: #96b0e4;
      color: white;
      width: 100%;
      position: absolute;
      bottom: -47px;
      right: 0;
    }
  }
}
