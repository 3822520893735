$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.counselingDetail {
    .recallManagement {
        .managementBar {
            display: flex;
            width: 100%;
            height: 4rem;
            background-color: white;
            box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.05);
            border-radius: 0.2rem;
            align-items: center;
            padding: 0.8rem 1.5rem;
            padding-left: 2.4rem;

            & > span {
                color: $secondary;
                font-size: 1.3rem;
                font-weight: bold;

                &:nth-child(2) {
                    color: black;
                    font-size: 1rem;
                    font-weight: unset;
                    margin: 0 1.3rem;
                }
            }

            .addDateInput {
                height: 100%;
                margin-left: auto;
                border: 1px solid rgba(0, 0, 0, 0.5);

                input {
                    height: 100%;
                    color: black;
                    text-align: left;
                    padding-left: 1.5rem;
                }
            }

            .addButton {
                margin-left: 0.8rem;
                height: 100%;

                &.removeMode {
                    color: white;
                    background-color: $error;
                }
            }
        }
        .recallManagementHeader,
        .recallManagementItem {
            font-size: 0.75rem;

            .rowWrapper {
                display: grid;
                grid-template-columns: fr(1) fr(15);

                .rowItem {
                    display: grid;
                    grid-template-columns: fr(1.3) fr(1.3) fr(3.5) fr(2) fr(6);
                    padding-left: 2rem;
                }
            }
        }

        .recallManagementHeader {
            text-align: center;
            margin-top: 1.2rem;
            margin-bottom: 0.8rem;
        }

        .recallManagementItem {
            .rowWrapper {
                height: 3.125rem;

                &:nth-child(n + 2) {
                    margin-top: 0.4rem;
                }

                & > span {
                    align-self: center;
                    text-align: center;
                }

                .rowItem {
                    background-color: white;
                    box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.05);
                    border-radius: 0.2rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    & > * {
                        height: 100%;
                    }

                    .recallDateInput {
                        display: flex;

                        input {
                            padding: 0;
                        }
                    }

                    .counselorSelectInput,
                    .recallDateInput,
                    .memoInput {
                        border: 1px solid rgba(0, 0, 0, 0.5);
                        margin: 0 0.2rem;

                        input {
                            color: black;
                        }
                    }

                    & > div:last-child {
                        display: flex;
                        justify-content: center;

                        .memoInput {
                            border-radius: 0.2rem;
                            width: 70%;
                            height: 100%;
                            display: flex;
                            input {
                                padding: 0 0.78rem;
                            }
                        }
                        .memoAddButton {
                            margin-left: 0.8rem;
                        }

                        button {
                            padding: 0;
                        }

                        .deleteIcon {
                            display: none;
                            color: $primary;
                            align-self: center;
                            margin-left: auto;
                            margin-right: 0.8rem;
                            cursor: pointer;

                            svg {
                                transform: scale(1.5);
                            }

                            &:hover {
                                color: darken($primary, 10%);
                            }

                            &:active {
                                color: darken($primary, 20%);
                            }
                        }
                    }

                    .dailyCallCount,
                    .callResult {
                        display: flex;
                        margin: 0 1.3rem;
                        text-align: center;
                        align-items: center;

                        & > * {
                            flex: 1;
                        }

                        .MuiTypography-root {
                            font-size: 0.75rem;
                        }

                        .MuiRadio-root {
                            padding: 0 0.56rem;
                        }
                    }
                }
            }
        }
    }
}
