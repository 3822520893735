$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.Tables.tm {
  input {
    border-radius: 0;
    outline: NONE;
    background-color: transparent !important;
    color: #333 !important;
    border: 0 !important;
    border-bottom: 1px solid #333 !important;
  }

  tbody tr td:nth-child(2) button:nth-child(2) {
    margin-left: 0.5rem;
  }

  span {
    justify-content: center;
  }
}

.GTF {
  margin-top: 2rem;
  > p {
    text-align: right;
    &.counselingGTF {
      text-align: left;
    }
    padding-right: 1rem;
  }

  .GlobalTable {
    font-size: 1rem;
    margin-top: 0.6rem;
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 135%;

    & > div {
      display: grid;
      flex-grow: 1;
      width: 100%;
      align-items: center;
      grid-template-columns: repeat(4, 1fr) repeat(7, 2fr) 1fr 2fr 2fr 1fr 1fr 2fr 2fr 1.2fr 2fr;
    }

    td {
      overflow-x: auto;
    }

    .GlobalTableHeader {
      height: 2rem;

      td:first-child {
        overflow-x: initial;

        > div {
          padding-top: 1rem;
          padding-bottom: 0.5rem;
        }
      }

      .filterButton {
        height: 3rem;
        width: 100%;

        .MuiButton-label {
          padding: 0;
        }

        min-width: 0;
      }

      input {
        outline: NONE;
        background-color: transparent !important;
        color: #333 !important;
        border: 0 !important;
        border-bottom: 1px solid #333 !important;
      }

      span {
        // 테이블 타이틀
        padding: 0 1rem;
        display: flex;

        // 테이블 타이틀

        // margin-left: 1rem;

        > .tmSortBtn {
          transform: translateY(-0.12rem);
          min-width: auto;
          padding: 2px 6px;
          font-size: 1rem;

          .MuiButton-label {
            padding: 0;
          }

          svg {
            color: #777;
            padding: 0;
          }
        }
      }
    }

    .GlobalTableItem {
      margin-top: 0.5rem;
      background-color: white;
      height: 3.625rem;
      box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.02);
      border-radius: 0.2rem;

      & tr {
        margin-top: 1rem;
      }

      &.disabledItem {
        background-color: rgba(255, 255, 255, 0.2);

        & > * {
          opacity: 0.4;
        }

        & > div:nth-child(1) {
          opacity: 1;
        }
      }

      .GlobalTableItemEditBox {
        svg {
          color: black;
          opacity: 0.55;

          &:hover {
            opacity: 1;
          }

          &:active {
            color: darken(black, 5%);
          }

          &:nth-child(1) {
            cursor: pointer;
          }
          &:nth-child(2) {
            stroke: black;
            stroke-width: 2;
            transform: translate(0.35rem, 0.15rem);
            pointer-events: none;
          }
          &:nth-child(3) {
            cursor: pointer;
            transform: translate(0.5rem, 0.15rem);
          }
        }
      }
    }

    .IconLine {
      position: relative;
      margin-right: 0.5rem;

      &:after {
        position: absolute;
        content: "";
        right: -0.4rem;
        top: -100%;
        width: 1px;
        height: 200%;
        background-color: black;
      }
    }
  }
}
