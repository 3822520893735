$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.management {
  display: grid;
  grid-template-columns: 1.1fr 3fr 1fr;
  min-width: 1570px;
  width: 130%;

  .managementTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #96b0e4;
    padding: 0.5rem;
    color: white;
    border-radius: 10px;
    height: 50px;
    box-shadow: 0px 0px 20px #00000033;
  }

  & > div:nth-child(2){
    margin: 0 0.5rem;
    width: calc(100% - 1rem);
  }
  
  & > div:nth-child(4) {
    .GlobalTable > div {
      & > div{
        width: 100%;
  
        &:nth-child(2){
          width: 50%;
        }
  
        &:nth-child(3){
          width: 50%;
        }
      }
    }
  }

  & > div:nth-child(5) {
    margin: 0 0.5rem;
    .GlobalTable > div {
      & > div{
        width: 10%;
  
        &:first-child{
          width: 50%;
          & > span{
            margin-left: 0.2rem;
          }
        }

        &:nth-child(2){
          width: 90%;
          & > span{
            margin-left: 0.7rem;
          }
        }
  
        &:nth-child(3){
          width: 50%;
          & > span{
            margin-left: -0.2rem;
          }
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 60%;
          & > span{
            margin-left: -1rem;
          }
        }

        &:nth-child(7){
          width: 50%;
          & > span{
            margin-left: -1.7rem;
          }
        }

        &:nth-child(8){
          width: 30%;
          & > span{
            margin-left: -1rem;
          }
        }

        &:nth-child(9){
          width: 80%;
          & > span{
            margin-left: -2rem;
          }
        }

        &:last-child{
          width: 30%;
          text-align: left;
        }
      }
    }
  }

  & > div:last-child {
    .GlobalTable > div {
      & > div{
        width: 100%;
      }
    }
  }

  .GlobalTable{
    margin-top: 1rem;
    width: auto;
    font-size: 1rem;

    .GlobalTableHeader {
      text-align: center;
      span {
        white-space : nowrap;
        margin-left: 0;
      }
    }

    & > div {
      background-color: rgba(0,0,0,0);
      box-shadow: none;
      width: 100%;
      display: flex;
      align-items: center;
    }

    & input, & .selectInput{
      box-shadow: 0.2rem 0.2rem 0.75rem #0000000d;
      margin: 0 0.25rem;
      width: calc(100% - 0.5rem);
      height: calc(100% + 0.5rem);
      background-color: white;
      color: gray;
    }

    & .MuiSelect-root{
      color: gray;
      font-size: 0.7rem;
      padding-right: 24px;
    }

    & svg {
      color: #96b0e4;
    }

    & .selectInput{
      text-align: center;
      height: 42px;
      font-size: 0.5rem;
    }

    & .MuiSelect-icon{
      font-size: 1rem;
      top: calc(50% - 9px);
    }

    & .TMButton{
      background-color: #96b0e4;
      padding: 0.5rem;
      color: white;
      border-radius: 35px;
      width: 80%!important;
      text-align: center!important;
      margin: 0 auto;
      cursor: pointer;
    }

    .GlobalTableItem{
      height: 1rem;
    }
  }
}