$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.statisticsManagement {
  /* display: grid;
  grid-template-columns: auto;
  grid-template-rows: 15.6rem 17.75rem 7.5rem auto; */

  .statisticsChart_1 {
    height: 15.6rem;

    .statisticsSummary {
      padding-right: 6rem;
      padding-left: 5rem;
      padding-top: 1.5rem;
    }
  }

  .statisticsChart_2 {
    height: 17.75rem;
  }

  .searchBarArea {
    display: flex;
    width: 100%;

    > div {
      width: 100%;
    }

    .excelDownButton {
      height: 3.3rem;
      font-size: 0.95rem;
      line-height: 2.5rem;
      padding-left: 1.25rem;
      padding-right: 1.7rem;
      align-self: flex-end;
      margin-left: auto;
      border-radius: 0.5rem;

      span {
        margin-left: 0.2rem;
      }

      svg {
        transform: translate(0, 0.4rem);
      }
    }
  }

  .salesStatisticsList {
    grid-row: 4/5;
  }

  .excelDownButtonWrap {
    display: flex;
    justify-content: flex-end;

    button {
      padding-bottom: .6rem;
    }

    svg {
      position: relative;
      top: 0.4rem;
      margin-right: .5rem;
    }
  }
}
