$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.dbStatistics {
  .statisticsChart_2 {
    display: flex;
    position: relative;
    background-color: white;
    color: $primary;
    border-radius: 0.85rem;
    box-shadow: 0.6rem 0.6rem 1rem rgba(#161360, 0.06);
    margin-top: 0.5rem;
    padding: 1.75rem;

    .lineChart_1,
    .lineChart_2,
    .barChart_1 {
      margin: 1.5rem 1rem 0rem 1rem;
      flex: 1;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      span {
        position: absolute;
        right: 0;
        background-color: $primary;
        padding: .4rem 1rem .3rem 1rem !important;
        border-radius: 1rem;
        color: white;
        font-size: 0.8rem;
        font-weight: bold;
        // margin: 0.2rem;
        margin: -1rem -1rem;
        pointer-events: none;
        user-select: none;
        top: -0.5rem;
      }
    }
  }
}
