$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

$editBox-color: #8aa1d7;

.counselingViewList {
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  align-items: center;
  text-align: center;

  & > div {
    display: grid;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    grid-template-columns: 3.65rem 3.65rem repeat(4, 7.3rem) 3.65rem 7.3rem 3.65rem 7.3rem 7.3rem 3.65rem 7.3rem 3.65rem 6rem;
  }

  .counselingViewListHeader {
    height: 2rem;
  }

  .counselingViewListItem {
    margin-top: 0.5rem;
    background-color: white;
    height: 3.625rem;
    box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.02);
    border-radius: 0.2rem;

    & > * {
      width: 100%;
    }

    &.disabledItem {
      background-color: rgba(255, 255, 255, 0.2);

      & > * {
        opacity: 0.4;
      }

      & > div:nth-child(1) {
        opacity: 1;
      }
    }

    a {
      margin-top: 3px;
    }

    a > .material-icons {
      color: #96B0E4;
    }

    & input,
    & .selectInput {
        margin: 0 0.25rem;
        width: calc(100% - 0.5rem);
        height: calc(100% + 0.5rem);
        background-color: #96B0E4;
        color: white;
    }

    & .MuiSelect-root {
        color: white;
        font-size: .75rem;
        padding-right: 24px;
    }

    & svg {
      color: white;
      margin-top: 2px;
    }

    & .selectInput {
        height: 2rem;
        margin-top: 0.25rem;
        font-size: 0.5rem;
    }

    & .MuiSelect-icon {
        font-size: 1rem;
        top: calc(50% - 9px);
    }

    .counselingViewListItemEditBox {
      svg {
        color: $editBox-color;
        opacity: 0.55;

        &:hover {
          opacity: 1;
        }

        &:active {
          color: darken($editBox-color, 5%);
        }

        &:nth-child(1) {
          cursor: pointer;
        }
        &:nth-child(2) {
          stroke: $editBox-color;
          stroke-width: 2;
          transform: translate(0.35rem, 0.15rem);
          pointer-events: none;
        }
        &:nth-child(3) {
          cursor: pointer;
          transform: translate(0.5rem, 0.15rem);
        }
      }
    }
  }
}
