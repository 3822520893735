$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

$pagebutton-color: $primary; //7f63f4

.counselingViewControl {
  display: flex;
  margin-top: 1.8rem;
  margin-bottom: 2.5rem;

  .counselingViewControlPages {
    display: flex;
    .PageButton {
      background-color: white;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      text-align: center;
      padding-top: .3rem;
      margin-right: 0.6rem;
      user-select: none;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      font-weight: bold;
      box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.08);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: .5rem;

      &[name="dots"] {
        pointer-events: none;
      }

      &:hover,
      &.active {
        color: white;
        background-color: $pagebutton-color;
      }

      &:active {
        background-color: darken($pagebutton-color, 5%);
      }

      span {
        pointer-events: none;
      }

      svg {
        pointer-events: none;
        position: relative;
        top: -.2rem;

        &:nth-child(1) {
          transform: translate(0.3rem, 0.1rem) scale(1.7);
        }
        &:nth-child(2) {
          transform: translate(-0.3rem, 0.1rem) scale(1.7);
        }
      }

      &[name="back"] {
        margin-right: 1.9rem;
      }
      &[name="forward"] {
        margin-left: 1.3rem;
      }
    }
  }

  button {
    font-size: 0.95rem;
    line-height: 2.5rem;
    padding-left: 2rem;
    padding-right: 1.7rem;

    svg {
      transform: translate(-0.6rem, -0.1rem);
    }
  }
  .excelDownButton {
    margin-left: auto;
    svg {
      transform: translate(-0.5rem, 0.4rem);
    }
  }
  .addButton {
    margin-left: 0.3rem;
  }
}
