$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.totalManagementDetail {
    // display: grid;
    // grid-template-rows: 11.75rem 8.3rem 4rem 8.37rem auto auto 4rem; //min-content

    .react-datepicker__input-container {
      height: 100%;

      input {
        height: 100%;
      }
    }

    .callTimeInput {
      border-radius: 0.3rem;
      border-width: 1px;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.5);

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }

    .splitBlock {
        display: flex;
        background-color: $primary;
        border-radius: 0.85rem;
        box-shadow: 0.6rem 0.6rem 1rem rgba(0, 0, 0, 0.12);
        color: white;
        width: auto;
        height: 3.3rem;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .excelDownButton {
        font-size: 1rem;
        width: 9.37rem;
        height: 3.3rem;
        margin-left: auto;

        span {
            margin-left: 0.2rem;
        }
    }

    .excelUploadBtn {
      width : auto!important;

      > span > svg {
        margin-right : .5rem;
        transform: translateY(-.05rem);
      }
      
      span {
        white-space: nowrap;
      }
    }

    .controls {
      margin-top: 1.5rem;
      text-align: right;

      button {
        margin-left: 0.3rem;
        width: 7.5rem;
        height: 3rem;
      }
    }
}
