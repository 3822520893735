$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.channelDataForm {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  color: $secondary;
  border-radius: 0.85rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  margin-top: 2rem;
  padding: 3rem 3.5rem;

  > div {
    width: 40%;
  }

  .channelDataFormItem {
    display: grid;
    grid-template-columns: 6.5rem auto;
    align-items: center;
    padding-bottom: 1rem;

    .inputs {
      > div {
        display: flex;
        align-items: center;
      }

      input {
        width: 10rem;
      } 
    }

    .selectName {
      .MuiSelect-root {
        padding-left: 1rem;
        padding-top: .5rem;
      }

      svg {
        transform: scale(1.8) translate(-0.6rem, -1px);
      }
    }

    & > *:nth-child(1) {
      align-self: start;
      margin-top: 1rem;
    }

    & > *:nth-child(2) {
      display: flex;
    }

    .selectInput.selectName {
      margin-right: 1rem;
    }
  }

  input[type="text"],
  input[type="number"],
  textarea {
    border: none;
    background-color: #f7f6ff;
    padding: 1rem;
    border-radius: 0.3rem;
    color: $secondary;
    width: 15.5rem;
    resize: none;

    &:active,
    &:focus {
      border: 1px solid;
      outline: none;
    }
  }
}
