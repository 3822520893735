$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.totalManagement {
    .viewTitle {
        padding-bottom: 0.75rem;
    }
    .dbStateManagement {
        display: block;

        & > div {
            display: flex;

            &:first-child {
                padding-bottom: 1rem;
                border-bottom: 1px solid white;

                & > div:first-child {
                    &:after {
                        content: "";
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        background-color: white;
                        right: 0;
                    }
                }
            }

            &:last-child {
                padding-top: 1rem;
            }

            & > div:first-child {
                display: flex;
                align-items: center;
                margin-right: 1.8rem;
                position: relative;

                & > span {
                    align-self: center;
                    padding-left: 1.8rem;
                    padding-right: 1.8rem;
                    -webkit-user-select: none;
                    user-select: none;
                    font-size: 1.1rem;
                    font-weight: normal;
                }
            }

            .DateGroup {
                display: flex;
                align-items: center;

                & button {
                    font-size: 1.2rem;
                    margin-left: 0.7rem;

                    & > span {
                        & > span {
                            padding-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    .Title {
        text-align: right;
        padding: 2.2rem 0;
    }

    .Tables {
        .GlobalTable {
            margin-top: 0;

            & > .GlobalTableHeader,
            & > .GlobalTableItem {
                grid-template-columns:
                    repeat(2, 0.8fr) // 체크박스, 번호
                    2fr // 상담항목
                    1.5fr // 재상담
                    1.2fr // 가망여부
                    1.2fr // 상태
                    1.5fr // TM
                    1fr // 경로
                    1.5fr // 세부경로
                    1fr // 이름
                    2fr // 전화번호
                    1fr // 나이
                    2.5fr // 시/구/동
                    2fr // 최초 DB 수집일자
                    2fr // 최근 상담일
                    1.2fr // 내원
                    1fr
                    ;
            }

            & input,
            & .selectInput {
                margin: 0 0.25rem;
                width: calc(100% - 0.5rem);
                height: calc(100% + 0.5rem);
                background-color: white;
                color: gray;
                border: 1px solid #96B0E4;
            }

            & .MuiSelect-root {
                color: gray;
                font-size: .75rem;
                padding-right: 24px;
            }

            & svg {
              color: #96b0e4;
              margin-top: 2px;
            }

            & .selectInput {
                height: 2rem;
                margin-top: 0.25rem;
                font-size: 0.5rem;
            }

            & .MuiSelect-icon {
                font-size: 1rem;
                top: calc(50% - 9px);
            }
        }
    }

    .Buttons {
        display: flex;
        justify-content: space-between;
    }
}
