$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

/* 
 * Roboto
 */
/* @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

/* 
 * Material Icons
 */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  src: url('./assets/fonts/AppleSDGothicNeoB.ttf');
  font-weight: 700;
  font-family: 'SDGothic';
}

@font-face {
  src: url('./assets/fonts/AppleSDGothicNeoEB.ttf');
  font-weight: 800;
  font-family: 'SDGothic';
}

@font-face {
  src: url('./assets/fonts/AppleSDGothicNeoH.ttf');
  font-weight: 900;
  font-family: 'SDGothic';
}

@font-face {
  src: url('./assets/fonts/AppleSDGothicNeoL.ttf');
  font-weight: 300;
  font-family: 'SDGothic';
}

@font-face {
  src: url('./assets/fonts/AppleSDGothicNeoM.ttf');
  font-weight: 500;
  font-family: 'SDGothic';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; // 모바일 터치영역 하이라이트 처리 숨김
}

html {
  font-size: $font_size;
  font-family: 'SDGothic';

  body {
    font-size: 20px;

    a {
      font-size: 18px;

      span{
        font-size: 1rem;
      }
    }
  }
}

.MuiButton-root,
.MuiInputBase-root,
.MuiTypography-body1 {
  font-family: 'SDGothic' !important;
}

.MuiTypography-body1 {
  font-size: 1rem !important;
  font-weight: 100 !important;
}

.totalManagement .dbStateManagement>div>div:first-child>span {
  padding-left: 0 !important;
}

.react-datepicker__input-container input {
  border-radius: .3rem !important;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

em {
  color: $primary;
  font-style: normal;
  background: none;
}

body {
  .MuiButton-root {
    text-transform: none;
  }
}

.Toastify__toast {
  background-color: rgba(#efeeee, 0.8);
  backdrop-filter: blur(15px);
  color: #323232;
  border-radius: 8px;
  box-shadow: none;
}

.Toastify__progress-bar {
  height: 4px;
}

.toastContent {
  display: flex;

  .warningIcon {
    color: #ffa726;
  }

  .material-icons {
    margin-right: 0.5rem;
  }

  .message {
    margin-top: 0.25rem;

    .title {
      font-size: 1rem;
      font-weight: 500;
      padding-left: 0 !important;
      padding-top: 0 !important;
    }

    .content {
      font-size: 0.85rem;
      margin-top: 0.3rem;
    }
  }
}

@include mobile {
  .Toastify__toast-container {
    padding: 1rem;
  }

  .Toastify__toast {
    margin-bottom: 0.5rem;
  }
}

.MuiPaper-root.MuiMenu-paper {
  max-height: 16rem;
}

.MuiButton-contained.MuiButton-containedPrimary {
  color: white;
}

.MuiButton-contained {
  box-shadow: 5px 5px 1rem rgba(#161360, .1) !important;
  border-radius: 5px !important;
}

body .react-datepicker__header {
  background: #96b0e4;
  color: #fff;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #fff !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background: #96b0e4 !important;
  color: #fff !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff !important;
}
.react-datepicker {
  border: none !important;
  box-shadow: 0 10px 20px lightgray !important;
}

.GlobalTable {
  .actionTitle {
    text-align: center;
  }
}

*::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,.04);
}

.viewTitle {
  color: $secondary;
  font-size: 1.5rem;
  font-weight: bold;
  align-self: end;
  padding-bottom: 0.75rem;
  user-select: none;
  display: block;
  padding-bottom: .5rem;
}

.statisticsChart_1 {
  box-shadow: 10px 10px 2rem rgba(#161360, .15) !important;
  display: flex;
  align-items: center;
}

.statisticsChart_2 {
  box-shadow: 1rem 1rem 4rem rgba(#161360, .15) !important;
}

.searchBar {
  box-shadow: 1rem 1rem 4rem rgba(#161360, .1) !important;
}

.statisticsSummary {
  background: #96b0e4;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem 3rem;

  &.typeUnderBorder {
    .summaryRow {
      
      .summaryItem {
        padding: .9rem 0;
      }
    }
  }

  &.right {
    margin-left: auto;
  }

  .summaryRow {
    display: flex;
    align-items: center;

    .summaryItem {
      display: flex;
      align-self: center;
      margin-right: 2rem;

      > div:nth-child(1) {
        display: flex;
        align-items: center;
        margin-right: .8rem;

        span {
          font-weight: 100;
        }
      }

      > div:nth-child(2) {
        font-size: 2.6rem !important;
        font-weight: bold;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}


  .searchButton {
    color: white !important;
    align-self: center !important;
    height: 3.5rem !important;
    margin-left: auto;
    margin-right: 1rem;
    border-radius: .9rem !important;
  
    .MuiButton-label {
      font-size: 1.2rem;
      font-weight: bold;
      padding-left: .3rem;
  
      span:nth-child(1) {
        padding-right: .9rem !important;
      }
  
      svg {
        width: 2rem !important;
      }
    }
  }

  .react-datepicker-popper .react-datepicker__triangle::before {
    display: none;
  }

  .react-datepicker-popper .react-datepicker__triangle::after {
    display: none;
  }


  .MuiButton-disableElevation {
    box-shadow: none !important;
  }

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.__react_component_tooltip.show button {
  font-size: 0.7rem!important;
  margin-left: 0rem!important;
}