$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.channelEvaluationList {
  display: flex;
  flex-direction: column;
  color: black;
  margin-top: 1.8rem;
  margin-bottom: 3rem;
  font-size: 0.79rem;
  align-items: center;
  text-align: center;

  .channelEvaluationListHeader,
  .channelEvaluationListItem,
  .channelEvaluationListTotal {
    display: grid;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    grid-template-columns: repeat(25, 1fr) 1.3fr;
    height: 3.8rem;
  }

  .channelEvaluationListHeader {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    .splitColumn {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.5);
      border-right: 1px solid rgba(0, 0, 0, 0.5);

      .columnRow {
        display: flex;
        flex: 1;
        align-items: center;

        &:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }

        & > * {
          flex: 1;
        }
      }
    }
  }

  .channelEvaluationListItem,
  .channelEvaluationListTotal {
    margin-top: 0.5rem;
    background-color: white;
    height: 3.625rem;
    box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.02);
    border-radius: 0.2rem;

    & > div {
      position: relative;
    }

    &.disabledItem {
      background-color: rgba(255, 255, 255, 0.2);

      & > * {
        opacity: 0.4;
      }
    }
  }

  .channelEvaluationListTotal {
    color: $secondary;

    & > *:first-child {
      grid-column: span 3;
      font-weight: bold;
    }
  }
}
