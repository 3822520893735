$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: $primary;
}

.sideFrame {
  width: 15rem;
  padding: 4.5rem 0;

  .sideNavigation {
    margin-top: 2rem;
    padding-left: 0.3rem;
  }

  .tools {
    display: none;
  }
}

.mainFrame {
  width: calc(100vw - 15rem);
  background-color: $background;
  border-radius: 3.75rem 0 0 3.75rem;
  box-shadow: 0 0 1.75rem rgba(0,0,0,.3);
  overflow-y: auto;

  .UI-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.8rem 3.4rem 0 3.4rem;
    position: relative;
    z-index: 999;
    
    background: $background;

    .right {
      display: flex;
      align-items: center;

      .topNavigation {
        margin-right: 5rem;
      }
    }
  }

  .contentWrap {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2.5rem;
      background: $background;
      z-index: 99;
    }
  }
  
  .contentView {
    max-width: calc(100vw - 15rem);
    padding-right: 3.4rem;
    position: relative;
    padding-top: 2.5rem;
    height: 80vh;
    padding-bottom: 5rem;

    .backgroundImage {
      position: relative;
      z-index: 100 !important;
      transform: translateY(18px);
    }
  }
}
