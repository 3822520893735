$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.smsModal {
  padding: 2rem 35rem;

  > div > div {
    padding: 0.1rem;
    border-radius: 1rem;
  }

  .miniTitle {
    padding: 1rem 0;
  }

  .smsInput {
    textarea {
      width: 100%;
      border: 0px;
      border-bottom: 1px solid #949494;
      resize: none;
      font-family: "SDGothic";
      font-size: 1.3rem;
      outline: none;
      padding: 1rem;
    }
  }

  .smsSelect {
    > div {
      font-size: 1.3rem;
      padding: 0.5rem;
    }
  }

  .previewBox {
    display: flex;
    justify-content: space-between;

    > .left,
    > .right {
      width: 50%;
    }

    > .left {
      padding-right: 1rem;
    }

    > .right {
      padding-left: 1rem;
    }
  }

  .smsModalContent {
    overflow: auto;
  }

  .smsModalActionBtn {
    button {
      font-size: 1.2rem;
    }
  }

  .smsPreviewMsg-background {
    > .smsPreviewMsg {
      height: 30rem;
      overflow-y: auto;
      > pre {
        width: 100%;
        white-space: break-spaces;
        font-family: "SDGothic";
        font-size: 1.25rem;
      }
    }

    background: #f9f9f9;
    padding: 1rem;
  }
}
