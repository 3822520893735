$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.callContentManagement {
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  .splitBlocks,
  .callContentManagementHeader,
  .rowWrapper {
    display: grid;
    grid-template-columns: fr(8) fr(2) fr(1) !important;

    & > * {
      margin: 0 0.3rem;
      width: auto;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .callContentManagementHeader,
  .callContentManagementItem {
    margin-top: 0.5rem;
    font-size: 1rem;

    .columnWrapper {
      display: grid;
      align-items: center;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &:nth-child(1) {
        grid-template-columns: repeat(3, fr(1)) fr(1.6) fr(1.3) fr(4.3);
      }
      &:nth-child(2) {
        grid-template-columns: fr(1);
      }
      &:nth-child(3) {
        grid-template-columns: fr(2);
      }

      &.isHead:nth-child(1) {
        grid-template-columns: repeat(3, fr(1)) fr(1.6) fr(5.6);
      }
    }
  }
  .callContentManagementItem {
    .rowWrapper {
      margin-top: -3.5rem;

      &.editMode {
        height: 11rem;

        textarea {
          height: 7rem;
          position: relative;
          top: 2.5rem;
        }

        .columnWrapper:nth-child(-n + 2) {
          pointer-events: unset;
          user-select: unset;
        }
      }

      .columnWrapper {
        &:first-child {
          & > *:last-child {
            input {
              text-align: left;
            }
          }
        }

        &:nth-child(-n + 2) {
          pointer-events: none;
          user-select: none;
        }

        & > div {
          margin: 0 0.1rem;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .textInput,
        .simpleSelectInput,
        .dateInput,
        .callTimeInput {
          height: 2rem;
          width: auto;

          input {
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 0.3rem;
            color: black;
          }

          .MuiSelect-root {
          }
        }

        .editBox {
          display: flex;
          flex-wrap: wrap;
          width: 137.69px;
          margin-top: 2.3rem;

          button {
            height: 2rem;
            font-size: 0.75rem;

            &.deleteButton {
              margin-left: 0.3rem;
            }

            &.applyButton {
              width: 100%;
            }
          }
        }

        .callDateInput,
        .recallRequestDateInput {
          display: flex;

          input {
            padding: 0;
          }
        }
      }
    }
  }
}
