$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #1e1e1e;
$primary: #96b0e4;
$secondary: #375ca4;
$error: #ec3232;
$background: #f1f5fb;
@function fr($fr) { @return minmax(0, $fr + fr); }

$font_size: 16px;

$primary_darken: darken($primary, 30);

$ui_button_margin: 5px;
$ui_button_padding: 8px 12px;
$ui_button_border_radius: 12px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line>1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.dbInfo {
  width: 100%;

  & > div {
    display: grid;
    grid-template-columns: fr(1.4) fr(2.5) fr(.8);
    width: 100%;
    align-items: center;
  }

  .splitBlocks,
  .columnWrapper {
    & > .splitBlock {
      margin: 0 0.2rem;
      width: auto;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .dbInfoHeader {
    align-items: center;

    .columnWrapper {
      align-items: center;
    }
  }

  .dbInfoItem {
    align-items: flex-start;

    .columnWrapper {
      align-items: flex-start;
    }
  }

  .dbInfoHeader,
  .dbInfoItem {
    margin-top: 0.5rem;
    font-size: 0.85rem;

    .columnWrapper {
      display: grid;
      text-align: center;
      margin: 0 0.2rem;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &:nth-child(1) {
        grid-template-columns: fr(1.5) fr(1) fr(1) repeat(3, fr(2));
      }
      &:nth-child(2) {
        grid-template-columns: fr(1.8) fr(3) fr(1.2) repeat(3, fr(2)) fr(1.5) fr(1.2) fr(2) fr(2);
      }
      &:nth-child(3) {
        grid-template-columns: fr(2.3) fr(1.3) fr(1.6);
      }
    }
  }
  .dbInfoItem {
    .columnWrapper {
      & > div {
        margin: 0 0.1rem;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      .textInput,
      .simpleSelectInput,
      .dateInput {
        height: 2rem;

        input {
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 0.3rem;
          color: black;
        }

        .MuiSelect-root {
        }
      }
      .recallRequired {
        display: flex;
        margin: auto 0.4rem;
        background-color: $primary;
        color: white;
        border-radius: 0.8rem;
        height: 1.6rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: darken($primary, 5%);
        }
        &:active {
          background-color: darken($primary, 15%);
        }
      }

      .reservationDateInput {
        display: flex;

        input {
          padding: 0;
        }
      }

      .MuiCheckbox-root {
        border-radius: 0.3rem;
        border: 1px solid rgba(0, 0, 0, 0.5);
        height: 2rem;
        background-color: white;
      }
    }
  }
}
